<script>
  let showPopup = true;
  function hidePopup() {
    showPopup = false;
  }

  function handleKeydown(event) {
    if (!event || !showPopup) {
      return;
    }

    if (event.key === "Escape") {
      hidePopup();
    }
  }
</script>

<style type="text/scss">
  .popup {
    &-container {
      position: absolute;
      z-index: 999999999;

      &__inner {
        background: white;
        display: flex;
        flex-flow: column nowrap;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        left: 50%;
        max-height: 80vh;
        max-width: 550px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
      }
    }

    &-title {
      align-items: center;
      background: #d23139;
      color: white;
      display: flex;
      padding: 1rem;
    }

    &-content {
      color: #333;
      flex-grow: 1;
      padding: 0.6rem 1rem;
      height: 10%;
      overflow: auto;

      img {
        display: block;
        margin: 1rem auto;
        max-width: 80%;
      }

      p {
        margin-top: 0;
        text-align: justify;
      }

      .actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        margin: 1.5rem 0 1rem;
      }

      .button {
        align-items: center;
        background: #333;
        color: white;
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        flex-grow: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: small;
        justify-content: center;
        padding: 0.5rem 3rem;
        text-align: center;
        text-decoration: none;

        &.variant {
          background: #d23139;
        }
      }
    }

    &-backdrop {
      background: rgba(33, 33, 33, 0.6);
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
</style>

<svelte:options tag={'uiwbajio-popup'} />
<svelte:window on:keydown={handleKeydown} />
{#if showPopup}
  <div class="popup-container">
    <div class="popup-backdrop" on:click={hidePopup} />
    <div class="popup-container__inner">
      <div class="popup-title">
        Aviso importante:
        <strong>UIW Cardinal Flight Plan to Fall 2020</strong>
      </div>
      <div class="popup-content">
        <img
          src="https://cardinalflightplan2020.uiwbajio.mx/static/media/logo2.5926da63.png"
          alt="UIW Campus Bajío" />
        <p>
          <em>¡Bienvenidos Cardinals!</em>, te invitamos a conocer el sitio
          <strong>UIW Cardinal Flight Plan to Fall 2020</strong>, para para
          obtener la información más actualizada sobre el otoño 2020.
        </p>

        <p>
          La pandemia del COVID-19 presenta un panorama en constante cambio al
          que las instituciones educativas deben continuar adaptándose para
          brindar un mejor servicio a los estudiantes.
        </p>

        <p>
          A medida que la situación continúe evolucionando, el sitio se
          actualizará para reflejar cualquier cambio en nuestro Cardinal Flight
          Plan.
        </p>
        <div class="actions">
          <button class="button" on:click={hidePopup}> Cerrar </button>
          <a
            href="https://cardinalflightplan2020.uiwbajio.mx/"
            target="_blank"
            el="noopener noreferrer"
            class="button variant"
            on:click={hidePopup}>
            Visitar
          </a>
        </div>
      </div>
    </div>
  </div>
{/if}
