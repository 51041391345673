<svelte:options tag={'uiwbajio-semaphore'} />

<script>
  import Axios from 'axios';

  async function getConfig() {
    const axios = Axios.create({
      baseURL: "https://cardinalflightplan2020.uiwbajio.mx/api/configuration",
    });

    try {
      const response = await axios.get('/get');
      return response.data;
    } catch (e) {
      return null;
    }
  }

  const result = getConfig();
</script>

<style>
  .semaphore-box {
    align-items: center;
    background: #aaa;
    border-radius: 35px 0px;
    color: white;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    flex-flow: row wrap;
    height: 70px;
    justify-content: center;
    outline: unset;
    overflow: hidden;
    position: fixed;
    right: 15px;
    bottom: 120px;
    text-decoration: unset;
    transition: all 0.1s ease-in-out;
    opacity: 0.9;
    width: 70px;
    z-index: 99999;

  }
  
  .semaphore-box:hover {
    transform: scale(1.2);
    opacity: 1;
  }

  .semaphore-box div {
    align-items: center;
    display: flex;
    font-size: 0.75rem;
    line-height: 1;
    justify-content: center;
    width: 100%;
  }

  .semaphore-icon {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1;
    margin-bottom: -1rem;
  }

  .semaphore-red {
    background :#cc3232;
  }

  .semaphore-orange {
    background: rgb(219, 123, 43);
  }

  .semaphore-yellow {
    background :#e7b416;
  }

  .semaphore-green {
    background :#2dc937;
  }
</style>

{#await result}
  <h1>Loading...</h1>
{:then data}
  {#if data.color && data.color != "unset"}
  <div>
    <a class="semaphore-box semaphore-{data.color}" 
      href="https://cardinalflightplan2020.uiwbajio.mx/"
      target="_blank"
      el="noopener noreferrer">
      <div class="semaphore-icon">
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 192 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"><path
            d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z" /></svg>
      </div>
      <div>Semáforo</div>
    </a>
  </div>
  {/if}
{/await}
